import { SORT_METHODS } from 'hooks/useSort';
import { LocalizationService } from 'service/localization/localizationService';

const indexType: Record<string, string> = {
  ALL: '_global_',
  PRODUCTS: '_products_',
  TIPS: '_tips_and_tricks_',
  RECIPES: '_recipe_',
  ARTICLES: '_articles_',
};

/**
 * Configures which algolia index to use based on a combination of
 * environment, data type, market, and sort direction. Each sort direction is
 * configured by a virtual replica index.
 */
export const getSortedIndexName = (
  type: string,
  recipeEnv: string,
  locale: string,
  sort: string,
): string => {
  switch (sort) {
    case SORT_METHODS.LATEST:
      return `${recipeEnv}${indexType[type]}${LocalizationService.getMarket(
        locale,
      )}_last_synced_desc`;
    case SORT_METHODS.TIME:
      return `${recipeEnv}${indexType[type]}${LocalizationService.getMarket(
        locale,
      )}_preparation_time_desc`;
    case SORT_METHODS.DIFFICULTY:
      return `${recipeEnv}${indexType[type]}${LocalizationService.getMarket(
        locale,
      )}_difficulty_asc`;
    case SORT_METHODS.RATINGS:
      return `${recipeEnv}${indexType[type]}${LocalizationService.getMarket(locale)}_top_rated`;
    case SORT_METHODS.RELEVENCE:
    default:
      return `${recipeEnv}${indexType[type]}${LocalizationService.getMarket(locale)}`;
  }
};
