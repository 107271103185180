import { useState } from 'react';

export enum SortItemType {
  ANY,
  PRODUCT,
  PRODUCT_SEARCH_RESULT,
  RECIPE,
  RECIPE_SEARCH_RESULT,
  TIPS_AND_TRICKS_SEARCH_RESULT,
  ARTICLES_SEARCH_RESULT,
}

// Sortable items should extend these interfaces to ensure compatability
export interface ISortableProduct {
  title: string;
  updatedAt: string;
}

export interface ISortableProductSearchResult {
  relevance: number; // Needs to be added to results based on the order returned from Algolia
  productTitle: string;
  updatedAt: string;
}

export interface ISortableRecipe {
  Title: string;
  ModifiedAt: Date;
  PreparationTimeInMinutes: number;
  Difficulty: number;
  Rating: {
    average_rating_score: number;
    rating_votes: number;
  };
}

export interface ISortableRecipeSearchResult {
  relevance: number; // Needs to be added to results based on the order returned from Algolia
  title: string;
  last_synced: string;
  preparation_time: number;
  difficulty: number;
  rating?: {
    average_rating_score: number;
    rating_votes: number;
  };
}

export interface ISortableTipsAndTricks {
  relevance: number; // Needs to be added to results based on the order returned from Algolia
  last_synced: string; // Needs to be added to the model in algolia
  title: string;
}

// Not currently in Algolia
export interface ISortableArticle {
  relevance: number;
  last_synced: string;
  title: string;
}

type SortableItem =
  | ISortableProduct
  | ISortableProductSearchResult
  | ISortableRecipe
  | ISortableRecipeSearchResult;

export const SORT_METHODS = {
  RELEVENCE: 'relevence',
  LATEST: 'latest',
  TIME: 'time',
  DIFFICULTY: 'difficulty',
  RATINGS: 'ratings',
};

// Default sort method should be listed first
export const ANY_SORT_METHODS = {
  RELEVENCE: SORT_METHODS.RELEVENCE,
  LATEST: SORT_METHODS.LATEST,
};

const PRODUCT_SORT_METHODS = {
  LATEST: SORT_METHODS.LATEST,
};

export const PRODUCT_SEARCH_SORT_METHODS = {
  RELEVENCE: SORT_METHODS.RELEVENCE,
  LATEST: SORT_METHODS.LATEST,
};

const RECIPE_SORT_METHODS = {
  LATEST: SORT_METHODS.LATEST,
  TIME: SORT_METHODS.TIME,
  DIFFICULTY: SORT_METHODS.DIFFICULTY,
  RATINGS: SORT_METHODS.RATINGS,
};

export const RECIPE_SEARCH_SORT_METHODS = {
  RELEVENCE: SORT_METHODS.RELEVENCE,
  LATEST: SORT_METHODS.LATEST,
  TIME: 'time',
  DIFFICULTY: 'difficulty',
  RATINGS: 'ratings',
};

export const TIPS_AND_TRICKS_SORT_METHODS = {
  RELEVENCE: SORT_METHODS.RELEVENCE,
  LATEST: SORT_METHODS.LATEST,
};

const ARTICLES_SORT_METHODS = {
  RELEVENCE: SORT_METHODS.RELEVENCE,
  LATEST: SORT_METHODS.LATEST,
};

const sortProductsLatest = (
  a: ISortableProduct | ISortableProductSearchResult,
  b: ISortableProduct | ISortableProductSearchResult,
) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();

const sortRelevance = (
  a: ISortableProductSearchResult | ISortableRecipeSearchResult | ISortableTipsAndTricks,
  b: ISortableProductSearchResult | ISortableRecipeSearchResult | ISortableTipsAndTricks,
) => b.relevance - a.relevance;

const sortRecipesLatest = (a: ISortableRecipe, b: ISortableRecipe) =>
  b.ModifiedAt.getTime() - a.ModifiedAt.getTime();

const sortRecipesTime = (a: ISortableRecipe, b: ISortableRecipe) =>
  b.PreparationTimeInMinutes - a.PreparationTimeInMinutes;

const sortRecipesDifficulty = (a: ISortableRecipe, b: ISortableRecipe) =>
  b.Difficulty - a.Difficulty;

const sortRecipesRating = (a: ISortableRecipe, b: ISortableRecipe) =>
  b?.Rating?.average_rating_score || 0 - a?.Rating?.average_rating_score || 0;

const sortRecipesSearchResultsLatest = (
  a: ISortableRecipeSearchResult,
  b: ISortableRecipeSearchResult,
) => new Date(b.last_synced).getTime() - new Date(a.last_synced).getTime();

const sortRecipesSearchResultsTime = (
  a: ISortableRecipeSearchResult,
  b: ISortableRecipeSearchResult,
) => b.preparation_time - a.preparation_time;

const sortRecipesSearchResultsDifficulty = (
  a: ISortableRecipeSearchResult,
  b: ISortableRecipeSearchResult,
) => b.difficulty - a.difficulty;

const sortRecipesSearchResultsRating = (
  a: ISortableRecipeSearchResult,
  b: ISortableRecipeSearchResult,
) => (b.rating?.average_rating_score || 0) - (a?.rating?.average_rating_score || 0);

const sortTipsAndTricksLatest = (a: ISortableTipsAndTricks, b: ISortableTipsAndTricks) =>
  new Date(b.last_synced).getTime() - new Date(a.last_synced).getTime();

/**
 * Use Sort Hook
 * @param sortItemType Optional default sortable item type
 * @returns
 */
export function useSort(sortItemType?: SortItemType) {
  const [currentSortItemType] = useState<SortItemType>(sortItemType || SortItemType.ANY);

  const getAvailableSortMethods = (newSortItemType?: SortItemType): string[] => {
    const siType = newSortItemType || currentSortItemType;
    switch (siType) {
      case SortItemType.PRODUCT:
        return Object.values(PRODUCT_SORT_METHODS);
      case SortItemType.PRODUCT_SEARCH_RESULT:
        return Object.values(PRODUCT_SEARCH_SORT_METHODS);
      case SortItemType.RECIPE:
        return Object.values(RECIPE_SORT_METHODS);
      case SortItemType.RECIPE_SEARCH_RESULT:
        return Object.values(RECIPE_SEARCH_SORT_METHODS);
      case SortItemType.TIPS_AND_TRICKS_SEARCH_RESULT:
        return Object.values(TIPS_AND_TRICKS_SORT_METHODS);
      case SortItemType.ARTICLES_SEARCH_RESULT:
        return Object.values(ARTICLES_SORT_METHODS);
      case SortItemType.ANY:
      default:
        return Object.values(ANY_SORT_METHODS);
    }
  };

  function sortItems(unsortedItems: SortableItem[], sortMethod: string): SortableItem[] {
    const items = [...unsortedItems];
    // Product (Non-Search Results) Sorting
    if (sortItemType === SortItemType.PRODUCT && sortMethod === PRODUCT_SORT_METHODS.LATEST) {
      (items as ISortableProduct[]).sort(sortProductsLatest);
    }

    // Product Search Results Sorting
    if (sortItemType === SortItemType.PRODUCT_SEARCH_RESULT) {
      if (sortMethod === PRODUCT_SEARCH_SORT_METHODS.RELEVENCE) {
        (items as ISortableProductSearchResult[]).sort(sortRelevance);
      }
      if (sortMethod === PRODUCT_SEARCH_SORT_METHODS.LATEST) {
        (items as ISortableProductSearchResult[]).sort(sortProductsLatest);
      }
    }

    // Recipe (Non-Search Results) Sorting
    if (sortItemType === SortItemType.RECIPE) {
      if (sortMethod === RECIPE_SORT_METHODS.LATEST) {
        (items as ISortableRecipe[]).sort(sortRecipesLatest);
      }
      if (sortMethod === RECIPE_SORT_METHODS.TIME) {
        (items as ISortableRecipe[]).sort(sortRecipesTime);
      }
      if (sortMethod === RECIPE_SORT_METHODS.DIFFICULTY) {
        (items as ISortableRecipe[]).sort(sortRecipesDifficulty);
      }
      if (sortMethod === RECIPE_SORT_METHODS.RATINGS) {
        (items as ISortableRecipe[]).sort(sortRecipesRating);
      }
    }

    // Recipe Search Results Sorting
    if (sortItemType === SortItemType.RECIPE_SEARCH_RESULT) {
      if (sortMethod === RECIPE_SORT_METHODS.LATEST) {
        (items as ISortableRecipeSearchResult[]).sort(sortRecipesSearchResultsLatest);
      }
      if (sortMethod === RECIPE_SORT_METHODS.TIME) {
        (items as ISortableRecipeSearchResult[]).sort(sortRecipesSearchResultsTime);
      }
      if (sortMethod === RECIPE_SORT_METHODS.DIFFICULTY) {
        (items as ISortableRecipeSearchResult[]).sort(sortRecipesSearchResultsDifficulty);
      }
      if (sortMethod === RECIPE_SORT_METHODS.RATINGS) {
        (items as ISortableRecipeSearchResult[]).sort(sortRecipesSearchResultsRating);
      }
    }

    // Recipe Search Results Sorting
    if (sortItemType === SortItemType.TIPS_AND_TRICKS_SEARCH_RESULT) {
      if (sortMethod === TIPS_AND_TRICKS_SORT_METHODS.RELEVENCE) {
        (items as ISortableTipsAndTricks[]).sort(sortRelevance);
      }
      if (sortMethod === TIPS_AND_TRICKS_SORT_METHODS.LATEST) {
        (items as ISortableTipsAndTricks[]).sort(sortTipsAndTricksLatest);
      }
    }

    // Default no sort
    return items;
  }

  return { getAvailableSortMethods, sortItems };
}
